import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-cart-stat';

class StockCartStat {

    /** 카트 수리 현황 조회 */
    async getCartRepairStat(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/repair/Stat` ,{params} );
        return data;
    }
}

export default new StockCartStat();